/* eslint-disable */
import React from "react";

const AboutSection1 = () => {
  return (
    <>
      <div className="container-fluid py-5" id="about">
        <div className="container-fluid px-lg-5">
          <div className="row g-5">
            {/* Texte à propos */}
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="section-title1 position-relative mb-4 pb-2">
                <h3 className="position-relative text-dark ps-4 fw-bolder ">
                  À Propos de Nous
                </h3>
                <h4 className="mt-2 ">
                  Une décennie d'expertise au service de votre réussite
                  financière
                </h4>
              </div>
              <p className="mb-4">
                Chez Bousanm, nous mettons la technologie et l'innovation au
                service de vos finances. Depuis plus de 10 ans, nous aidons nos
                utilisateurs à gérer leur argent intelligemment, tout en leur
                offrant des solutions flexibles et adaptées à leurs besoins.
                Rejoignez une communauté qui place la simplicité, l'efficacité
                et la transparence au cœur de ses services.
              </p>
              <div className="row g-3">
                <div className="col-sm-6">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-2"></i>Solutions
                    Innovantes
                  </h5>
                  <h5 className="mb-0">
                    <i className="fa fa-check text-primary me-2"></i>Équipe
                    Dédiée
                  </h5>
                </div>
                <div className="col-sm-6">
                  <h5 className="mb-3">
                    <i className="fa fa-check text-primary me-2"></i>Assistance
                    24/7
                  </h5>
                  <h5 className="mb-0">
                    <i className="fa fa-check text-primary me-2"></i>Tarifs
                    Transparents
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4">
                <a className="btn btn-primary rounded-pill px-4 me-3" href="#">
                  En savoir plus
                </a>
                <a className="btn btn-outline-primary btn-square me-3" href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-primary btn-square me-3" href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-outline-primary btn-square me-3" href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="btn btn-outline-primary btn-square" href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            {/* Image à propos */}
            <div className="col-lg-6">
              <img
                className="img-fluid wow zoomIn"
                data-wow-delay="0.5s"
                src="img/about.png"
                alt="About Us"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection1;

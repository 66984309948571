/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { motion } from "framer-motion";
import "./HeroSection.css";

const Header1 = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 45);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logoSrc = isMobile
    ? "/img/logo.png"
    : isScrolled
    ? "/img/logo.png"
    : "/img/logo1.png";

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };

  const handleDownloadAndroid = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      const link = document.createElement("a");
      link.href = "/downloads/bousanm.apk";
      link.download = "bousanm.apk";
      link.click();
    }, 2000); // Simule un délai pour l'effet de chargement
  };

  return (
    <div
      className={`container-fluid position-relative p-0 ${
        isMobile ? "mobile-header" : ""
      }`}
    >
      <nav
        className={`${
          !isMobile
            ? `navbar navbar-expand-lg ${
                isScrolled
                  ? "navbar-light sticky-top shadow-sm"
                  : "navbar-light bg-transparent"
              } text-white px-4 px-lg-5 py-3 py-lg-0 fixed-top`
            : "navbar navbar-expand-lg bg-light navbar-light px-4 px-lg-5 py-3 py-lg-0 fixed-top"
        }`}
      >
        <a href="#" className="navbar-brand p-0">
          <img src={logoSrc} alt="Logo Bousanm" />
        </a>
        <button
          className="navbar-toggler float-end"
          type="button"
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-label="Toggle navigation"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
          id="navbarCollapse"
        >
          <div className="navbar-nav ms-auto py-0">
            <Link
              to="home"
              className="nav-item nav-link active"
              smooth={true}
              duration={500}
              onClick={closeMenu}
            >
              Accueil
            </Link>
            <Link
              to="about"
              className="nav-item nav-link"
              smooth={true}
              duration={500}
              onClick={closeMenu}
            >
              À propos
            </Link>
            <Link
              to="service"
              className="nav-item nav-link"
              smooth={true}
              duration={500}
              onClick={closeMenu}
            >
              Services
            </Link>
            <Link
              to="portfolio"
              className="nav-item nav-link"
              smooth={true}
              duration={500}
              onClick={closeMenu}
            >
              Nos Plans
            </Link>
            <Link
              to="faq"
              className="nav-item nav-link"
              smooth={true}
              duration={500}
              onClick={closeMenu}
            >
              FAQs
            </Link>
            {/* <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Plus
              </a>
              <div className="dropdown-menu m-0">
                <a href="team.html" className="dropdown-item">
                  Notre Équipe
                </a>
                <a href="testimonial.html" className="dropdown-item">
                  Témoignages
                </a>
                <a href="404.html" className="dropdown-item">
                  Page Introuvable
                </a>
              </div>
            </div> */}
            <Link
              to="contact"
              className="nav-item nav-link"
              smooth={true}
              duration={500}
              onClick={closeMenu}
            >
              Contact
            </Link>
          </div>

          <a
            href="https://app.bousanm.com/"
            className="btn btn-success  rounded-pill py-1 px-4 ms-3"
          >
            Se Connecter
          </a>
        </div>
      </nav>

      <div
        id="home"
        className="container-fluid py-5 bg-primary hero-header mb-5"
        onClick={closeMenu}
      >
        <div className="container-fluid my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-lg-6 text-lg-start">
              <h1 className="text-white mb-4 animated zoomIn display-6">
                La solution digitale qui révolutionne la gestion de votre argent
                : simple, rapide et intelligente.
              </h1>
              <p className="text-white pb-3 animated zoomIn">
                Avec Bousanm, profitez de solutions financières innovantes pour
                vos besoins de gestion d’épargne, de prêts, de placements et de
                mutuelle. Accédez à des outils modernes pour une meilleure prise
                en main de vos finances.
              </p>
              <a
                href="https://app.bousanm.com/register"
                className="btn btn-outline-light py-sm-2 px-sm-5 mb-2 me-3 rounded-pill animated slideInRight"
              >
                Créer un Compte
              </a>
              <a
                // href="#"
                onClick={handleOpenModal}
                className="btn btn-light py-sm-2 px-sm-5 mb-2 rounded-pill animated text-dark fw-bold slideInLeft"
              >
                Télecharger l'Application
              </a>
            </div>
            <div className="col-lg-6 text-center text-lg-start hero-image animated slideInLeft">
              <img
                className="img-fluid"
                src="asset/img/illustration-2.png"
                alt="Illustration Bousanm"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <CSSTransition
        in={showModal}
        timeout={300}
        classNames="modal"
        unmountOnExit
      >
        <div className="modal-backdrop" onClick={handleBackdropClick}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button
              onClick={handleCloseModal}
              className="close-button"
              aria-label="Fermer le modal"
            >
              &times;
            </button>
            <h5>Télécharger l'application</h5>
            <p>Choisissez votre plateforme pour télécharger l'application :</p>
            <div className="row p-0">
              <div className="col-md-6 mb-2">
                <button
                  type="button"
                  className="btn btn-success w-100 py-1"
                  onClick={handleDownloadAndroid}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <i className="fas fa-spinner fa-spin"></i> Preparation en
                      cours...
                    </>
                  ) : (
                    <>Android</>
                  )}
                </button>
              </div>
              <div className="col-md-6 ">
                <button
                  type="button"
                  className="btn bg-primary w-100 py-1"
                  // onClick={handleDownloadAndroid}
                  disabled
                >
                  <i className="fas fa-spinner fa-spin"></i> <>IOS</> en
                  cours...
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Header1;

import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import FAQSection from "../landing/FAQSection";
import ContactSection from "../landing/ContactSection";
import "./home.css";
import PortfolioSection from "../landing/PortfolioSection";
import Header1 from "../landing/Header1";
import AboutSection1 from "../landing/AboutSection1";
import ServicesSection1 from "../landing/ServicesSection1";
import Features2 from "../landing/Features2";
import CallToAction from "../landing/CallToAction";
import Footer1 from "../landing/Footer1";

function Home() {
  const [loading, setLoading] = useState(true);
  // const location = useLocation();

  useEffect(() => {
    // Simulation de chargement de page
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Vérifier si on doit scroller vers la section contact
    const searchParams = new URLSearchParams(window.location.search);
    const scrollTo = searchParams.get('scrollTo');

    if (!loading && scrollTo === 'contact') {
      const timeout = setTimeout(() => {
        const contactElement = document.getElementById("contact");
        if (contactElement) {
          contactElement.scrollIntoView({ 
            behavior: "smooth",
            block: "start"
          });
        }
      }, 300);

      return () => clearTimeout(timeout);
    }
  }, [loading]); // Ne dépend que du loading

  return (
    <div className="container-fluid bg-white p-0 w-100">
      {loading ? (
        <div className="loader-containerh w-100">
          <div className="loaderh"></div>
          <img
            src="/img/logo.png"
            style={{ width: "70px", height: "70px" }}
            alt="Logo"
            className="loader-logo"
          />
        </div>
      ) : (
        <>
          <Header1 />
          <AboutSection1 />
          <Features2 />
          <ServicesSection1 />
          <PortfolioSection />
          <FAQSection />
          <ContactSection />
          <CallToAction />
          <Footer1 />
        </>
      )}
    </div>
  );
}

export default Home;

// App.js
import React from 'react';
import AppRoutes from './components/routes/Routes';
function App() {

  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
